.withdrawal_card {
  position: relative;
}

.withdrawal_card .overlay {
  transform: translateY(100%);
  transition: all 300ms ease-in-out;
}

.withdrawal_card:hover .overlay {
  transform: translateY(-50%);
}

/* date range picker css */
.rdrDateRangePickerWrapper {
  font-family: sans-serif;
  display: flex;

  .rdrStaticRangeLabel {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    line-height: 25px;
    font-size: 14px;
  }
  .rdrDayToday .rdrDayNumber span:after {
    background-color: #3f51b5;
  }
  .datePicker {
    justify-content: space-around;
  }
  .rdrStaticRange {
    border: none;
  }
  .rdrCalendarWrapper,
  .rdrDefinedRangesWrapper {
    flex: 1;
    border: none;
  }
}

@media (max-width: 600px) {
  .rdrDateRangePickerWrapper {
    display: block !important;
  }

  .rdrDefinedRangesWrapper {
    width: 330px;
  }
}

@media (prefers-color-scheme: dark) {
  .rdrDateRangePickerWrapper {
    background-color: #1e1e1e;
  }
  .rdrCalendarWrapper,
  .rdrDefinedRangesWrapper {
    background-color: #1e1e1e;
  }
}

@media (prefers-color-scheme: light) {
  .rdrDateRangePickerWrapper {
    background-color: #ffffff;
  }
  .rdrCalendarWrapper,
  .rdrDefinedRangesWrapper {
    background-color: #ffffff;
  }
}

/* date range picker css */

@keyframes ldio-4j5ay0xf86g {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.ldio-4j5ay0xf86g div {
  left: 50%;
  top: 50%;
  transform: translate(-50% -50%);
  position: absolute;
  animation: ldio-4j5ay0xf86g linear 1s infinite;
  background: #000;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  transform-origin: 0px 15px;
}
ldio-4j5ay0xf86g div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -0.9s;
  background: #fff;
}

.ldio-4j5ay0xf86g div:nth-child(2) {
  transform: rotate(60deg);
  animation-delay: -0.75s;
  background: #fff;
}

.ldio-4j5ay0xf86g div:nth-child(3) {
  transform: rotate(120deg);
  animation-delay: -0.6s;
  background: #fff;
}

.ldio-4j5ay0xf86g div:nth-child(4) {
  transform: rotate(180deg);
  animation-delay: -0.45s;
  background: #fff;
}

.ldio-4j5ay0xf86g div:nth-child(5) {
  transform: rotate(240deg);
  animation-delay: -0.2s;
  background: #fff;
}

.ldio-4j5ay0xf86g div:nth-child(6) {
  transform: rotate(300deg);
  animation-delay: -0.15s;
  background: #fff;
}

.ldio-4j5ay0xf86g div:nth-child(7) {
  transform: rotate(360deg);
  animation-delay: -0s;
  background: #fff;
}

.loadingio-spinner-spinner-977el9wwy2v {
  width: 50px;
  height: 100%;
  display: inline-block;
  overflow: hidden;
}

.ldio-4j5ay0xf86g {
  width: 100%;
  height: 100%;
  position: relative;
  top: -15px;
  left: -10px;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
  /* see note above */
  display: flex;
  justify-items: center;
}

.ldio-4j5ay0xf86g div {
  box-sizing: content-box;
}
